import React from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import BreadCrumb from '../Components/Common/BreadCrumb/BreadCrumb'
import ContactDetails from '../Components/ContactPage/ContactDetails/ContactDetails'
import ContactForm from '../Components/Common/ContactForm/ContactForm'
import Footer from '../Components/Common/Footer/Footer'
import Map from '../Components/ContactPage/Map/Map'

function Contact() {
  return (
    <div className='Contact'>
      <Navbar />
      <BreadCrumb nav={'Contact'} />
      <ContactDetails />
      <Map />
      <ContactForm />
      <Footer />
    </div>
  )
}

export default Contact