import React from 'react';
import '../../../styles/Map.css'

function Map() {
    return (
        <div className='Map'>
            <div className="container">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15651.409817230215!2d75.800271!3d11.2722544!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ba65957a7e4664d%3A0xb26fde21db36f8ce!2sCasero%20technologies!5e0!3m2!1sen!2sin!4v1720684554813!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    )
}

export default Map