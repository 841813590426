import React from 'react';
import '../../../styles/BreadCrumb.css';
import { Link } from 'react-router-dom';

function BreadCrumb({nav}) {
  return (
    <div className='BreadCrumb'>
        <ul data-aos="fade"data-aos-duration="1000">
                <li><Link to="/">Home</Link></li>
                <li className='nav'>{nav}</li>
            </ul>
    </div>
  )
}

export default BreadCrumb