import React, { useEffect, useState } from 'react';
import '../../../styles/CustomCursor.css';

const CustomCursor = () => {
  const [dotPosition, setDotPosition] = useState({ x: -50, y: -50 });
  const [circlePosition, setCirclePosition] = useState({ x: -50, y: -50 });
  const [isMoving, setIsMoving] = useState(false);

  useEffect(() => {
    const handleMouseMove = (event) => {
      setIsMoving(true);
      setDotPosition({ x: event.clientX, y: event.clientY });
    };

    const updateCirclePosition = () => {
      setCirclePosition((prev) => ({
        x: prev.x + (dotPosition.x - prev.x) * 0.05,
        y: prev.y + (dotPosition.y - prev.y) * 0.05,
      }));
    };

    window.addEventListener('mousemove', handleMouseMove);
    const interval = setInterval(updateCirclePosition, 10);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      clearInterval(interval);
    };
  }, [dotPosition]);

  return (
    <>
      <div
        className={`dot ${isMoving ? 'active' : ''}`}
        style={{ left: `${dotPosition.x}px`, top: `${dotPosition.y}px` }}
      />
      <div
        className={`circle ${isMoving ? 'active' : ''}`}
        style={{ left: `${circlePosition.x}px`, top: `${circlePosition.y}px` }}
      />
    </>
  );
};

export default CustomCursor;
