import React from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import HeroSection from '../Components/HomePage/HeroSection/HeroSection'
import BriefServices from '../Components/HomePage/BriefServices/BriefServices'
import ProductSection from '../Components/HomePage/ProductSection/ProductSection'
import Testimonials from '../Components/HomePage/Testimonials/Testimonials'
import ContactForm from '../Components/Common/ContactForm/ContactForm'
import Footer from '../Components/Common/Footer/Footer'

function Home() {
  return (
    <div className='Home'>
        <Navbar/>
        <HeroSection/>
        <BriefServices/>
        <ProductSection/>
        <Testimonials/>
        <ContactForm/>
        <Footer/>
    </div>
  )
}

export default Home