import React from 'react';
import '../../../styles/ProductSection.css'

// import { Swiper, SwiperSlide } from 'swiper/react';

// // Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/effect-fade';
// import 'swiper/css/navigation';
// import 'swiper/css/pagination';

// // import required modules
// import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';


function ProductSection() {
    return (
        <div className='ProductSection'>
            <div className="container">
                <div className="top-section" data-aos="fade" data-aos-duration="1000">
                    <h2>Transform Your Home: View Our Brochure to See How</h2>
                    <p>Explore how Casero Technologies can upgrade your space with our advanced automation solutions. Download our brochure to learn more about our services and see how we can enhance your home’s comfort and efficiency.</p>
                </div>

                <div className="button">
                    <a href="doc/Casero Brochure.pdf">View Brochure</a>
                </div>
                {/* <Swiper
                    slidesPerView={2}
                    centeredSlides={true}
                    spaceBetween={20}
                    pagination={{
                        type: 'fraction',
                    }}
                    navigation={true}
                    speed={1000}
                    loop={true}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, EffectFade, Navigation, Pagination]}
                    className="mySwiper"
                    data-aos="fade-up" data-aos-duration="1000"
                    breakpoints={{

                        992: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                        }
                    }}
                >
                    <SwiperSlide>
                        <div className="card">
                            <div className="img-container">
                                <img src="img/products/pr1.png" alt="card" />
                            </div>
                            <h3>FIBARO UNIVERSAL BINARY SENSOR</h3>
                            <p>The Universal Binary Sensor is a wireless module that makes it possible to improve the...</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="img-container">
                                <img src="img/products/pr2.png" alt="card" />
                            </div>
                            <h3>FIBARO BUTTON</h3>
                            <p>The Universal Binary Sensor is a wireless module that makes it possible to improve the...</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="img-container">
                                <img src="img/products/pr3.png" alt="card" />
                            </div>
                            <h3>FIBARO DIMMER2</h3>
                            <p>The Universal Binary Sensor is a wireless module that makes it possible to improve the...</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="img-container">
                                <img src="img/products/pr3.png" alt="card" />
                            </div>
                            <h3>FIBARO DIMMER3</h3>
                            <p>The Universal Binary Sensor is a wireless module that makes it possible to improve the...</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="img-container">
                                <img src="img/products/pr3.png" alt="card" />
                            </div>
                            <h3>FIBARO DIMMER4</h3>
                            <p>The Universal Binary Sensor is a wireless module that makes it possible to improve the...</p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="card">
                            <div className="img-container">
                                <img src="img/products/pr3.png" alt="card" />
                            </div>
                            <h3>FIBARO DIMMER5</h3>
                            <p>The Universal Binary Sensor is a wireless module that makes it possible to improve the...</p>
                        </div>
                    </SwiperSlide>



                </Swiper> */}
            </div>


        </div>
    )
}

export default ProductSection