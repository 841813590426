import React from 'react';
import '../../../styles/ContactForm.css'
import { Link } from 'react-router-dom';

function ContactForm() {
  return (
    <div className='ContactForm'>
        <div className="container">
            <h2 data-aos="fade"data-aos-duration="1000">CONTACT US</h2>

            <form data-aos="fade"data-aos-duration="1000">
                <div className="form-group">
                    <input type="text" placeholder="FIRST NAME" />
                    <input type="text" placeholder="LAST NAME" />
                </div>
                <div className="form-group">
                    <input type="text" placeholder="MOBILE NUMBER" />
                    <input type="email" placeholder="YOUR EMAIL" />
                </div>
                <div className="form-group">
                    <textarea placeholder="YOUR MESSAGE"></textarea>
                </div>
                <div className="button-section">
                <button type='submit' >SEND MESSAGE <span><img src="../img/send.png" alt="send" /></span></button>

                </div>

            </form>
        </div>
    </div>
  )
}

export default ContactForm