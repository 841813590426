import React from 'react';
import '../../../styles/ServiceIntro.css'

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';


function ServiceIntro() {
    return (
        <div className='ServiceIntro'>
            <div className="container">
                <div className="top-content" data-aos="fade" data-aos-duration="1000">
                    <h2>SMART HOME SOLUTION</h2>
                    <p>A smart home is really all about home automation putting together the right smart devices, smart electronics, appliances and gadgets – and programming them to do what you want automatically. And, if you’ve been planning to get started on a project to make your home smarter, it actually easier to do now than ever before.</p>
                </div>
                <div className="card-container"
                    data-aos="fade-up" data-aos-duration="1000"
                >
                    <Swiper
                        slidesPerView={1}
                        centeredSlides={true}
                        pagination={true}
                        navigation={true}
                        speed={1000}
                        loop={true}
                        autoplay={{
                            delay: 3000,
                            disableOnInteraction: false,
                        }}
                        modules={[Autoplay, EffectFade, Navigation, Pagination]}
                        className="mySwiper"

                    >

                        <SwiperSlide>
                            <div className="img-container">
                                <img src="img/service-page/photo-1513584684374-8bab748fbf90.avif" alt="smart home" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-container">
                                <img src="https://images.unsplash.com/photo-1558002038-1055907df827?q=80&w=1470&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="smart home" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-container">
                                <img src="img/service-page/[Downloader.la]-6694b6d879553.jpg" alt="smart home" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-container">
                                <img src="img/service-page/[Downloader.la]-6694b9248e982.jpg" alt="smart home" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-container">
                                <img src="img/service-page/cctv.jpg" alt="smart home" />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="img-container">
                                <img src="img/service-page/shutter.jpg" alt="smart home" />
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div>





            </div>
        </div>
    )
}

export default ServiceIntro