import React from "react";
import "../../../styles/TestimonialList.css";

function TestimonialList() {
  return (
    <div className="TestimonialList">
      <div className="container">
        <div className="top-content" data-aos="fade" data-aos-duration="1000">
          <h2>WE HEAR FROM OUR CUSTOMERS</h2>
          <p>
            We prioritize customer feedback to refine and improve our products
            and services, ensuring we meet the evolving needs of our community
            with excellence and dedication.
          </p>
        </div>
        <div
          className="TestimonialList-container"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="card">
            <p>
              The team of Casero Technologies is very supportive and
              professionals. I have very good experience with this team.
              Brilliant solution makers and supplies premium quality products.
              Service's are also on time. So we don't have to worry about any
              other things.
            </p>
            <div className="profile">
              <img src="img/testimonials/BEATDROPPER.png" alt="profile" />
              <h3>BEATDROPPER</h3>
            </div>
          </div>
          <div className="card">
            <p>
              Casero did an amazing job in our sites . We strongly recommend
              Casero service given to us . Casero is the best shutter automation
              in Kerala
            </p>
            <div className="profile">
              <img src="img/avatar.webp" alt="profile" />
              <h3>Subramanyan V.A</h3>
            </div>
          </div>
          <div className="card">
            <p>
            I found them to be very professional and they knew what they were doing. When I asked for changes they did it without any fuss. Thank you all for an excellent job done!
            </p>
            <div className="profile">
            <img src="img/avatar.webp" alt="profile" />
              <h3>M PC</h3>
            </div>
          </div>
          <div className="card">
            <p>
              I have loved the fact that Casero Technologies is very
              professional in their approach and they gave me that dream
              automated home that I always wanted . I would recommend them to
              everyone who is looking for home automation services.
            </p>
            <div className="profile">
              <img src="img/testimonials/Aswathi.png" alt="profile" />
              <h3>Aswathi Dinil</h3>
            </div>
          </div>
          <div className="card">
            <p>
              Very good behaviour The quality of the work is superb really
              professional, the work was completed on date I am very happy with
              the work thank you casero technologies.
            </p>
            <div className="profile">
              <img src="img/testimonials/Ashwin.png" alt="profile" />
              <h3>Ashwin pukaat</h3>
            </div>
          </div>
          <div className="card">
            <p>
              Many thanks to Casero Technologies, Approached them regarding
              shutter automation. quality work, dedicated staff and work.
            </p>
            <div className="profile">
              <img src="img/testimonials/ghana.png" alt="profile" />
              <h3>Ghana syam</h3>
            </div>
          </div>
          <div className="card">
            <p>
            Hi All, Recently I have installeld automatic sliding gate at my home through Casero Technologies, I am quiite happy with their final product and quality of work. I am also pleased with the way the team interacted with me during design and installation, listened well to our requirements and provided valuable  insights and feedback to the suggestions. Arun and team is always willling to go extra mile to statisfy the customer needs, thank you
            </p>
            <div className="profile">
            <img src="img/avatar.webp" alt="profile" />
              <h3>Mansoor Muhamed</h3>
            </div>
          </div>
          <div className="card">
            <p>
              If you are looking at automating your house ,hese guys are the
              best you can find . What stands out is not just their expertise
              and experience , it is also their excellent service after
              installation. I would definitely refer them to everyone who wants
              to make their life more comfortable with just a touch on their
              smart phone .All the best to Casero. May you grow multifold!!
            </p>
            <div className="profile">
              <img src="img/testimonials/Aswathi-kp.png" alt="profile" />
              <h3>Aswathi kp</h3>
            </div>
          </div>
          <div className="card">
            <p>
            Great experience with casero. Their service quality is very good, very professional. Thank you somuch casero technologies
            </p>
            <div className="profile">
            <img src="img/avatar.webp" alt="profile" />
              <h3>Nithyasree P P</h3>
            </div>
          </div>
          
        </div>
      </div>
    </div>
  );
}

export default TestimonialList;
