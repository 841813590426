import React, { useEffect, useRef, useState } from 'react';
import '../../../styles/CounterSection.css';
import CountUp from 'react-countup';

const CounterCard = ({ end, duration, aosDelay, title, icon }) => {
    const [isVisible, setIsVisible] = useState(false);
    const counterRef = useRef();

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threeshold: 0.5,
        };
        const handleIntersection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                }
            })
        }

        const observer = new IntersectionObserver(handleIntersection, options);
        observer.observe(counterRef.current);

        return () => {
            observer.disconnect();
        }
    }, [])

    return (
        <div className="CounterSection" ref={counterRef} data-aos="fade-up" data-aos-duration="1000" data-aos-delay={aosDelay}>
            {isVisible && <CountUp end={end} duration={duration} />}<span>+</span>
            <h3>{title}</h3>
        </div>
    )
}

function CounterSection() {
    return (
        <div className="CounterContainer">
            <div className="counter-row">
                <CounterCard end={20} duration={3} aosDelay={100} title={'Ongoing'} />
                <CounterCard end={400} duration={3} aosDelay={200} title={'Projects'} />
                <CounterCard end={500} duration={3} aosDelay={300} title={'Clients'} />
            </div>
        </div>
    )
}

export default CounterSection