import React from "react";
import "../../../styles/ServiceList.css";

function ServiceList() {
  return (
    <div className="ServiceList">
      <div className="container" data-aos="fade-up" data-aos-duration="1000">
        <div className="card">
          <div className="img-container">
            <img src="img/cctv (1).png" alt="cctv" />
          </div>
          <h3>Camera</h3>
          <p>
          We offer advanced smart camera solutions designed to enhance the security of your home or business. Our cameras provide real-time monitoring, high-quality recording, and seamless remote access, allowing you to keep an eye on your property from anywhere at any time. Whether you need indoor or outdoor surveillance, our systems are tailored to meet your specific security needs, offering reliable protection and peace of mind.
          </p>
        </div>
        <div className="card">
          <div className="img-container">
            <img src="img/gate (4).png" alt="gate " />
          </div>
          <h3>Gate</h3>
          <p>
          We offer automated gate solutions that provide secure and convenient access control for your home or business. Our systems allow you to open, close, and monitor gates remotely or schedule automatic operations, ensuring both security and ease of use. With smart integration, you can control your gates effortlessly from your smartphone or other devices, enhancing the safety and convenience of your property.
          </p>
        </div>
        <div className="card">
          <div className="img-container">
            <img src="img/smart-light (1).png" alt="light automation" />
          </div>
          <h3>Light automation</h3>
          <p>
          We offer intelligent light automation solutions that allow you to control, dim, and schedule lighting throughout your home or business. With customizable settings, you can easily create the perfect ambiance for any space, improve energy efficiency, and enhance convenience. Our systems integrate seamlessly with your smart devices, giving you full control over your lighting from anywhere, at any time, whether you want to brighten a room, set mood lighting, or automate schedules for added convenience and savings.
          </p>
        </div>
        <div className="card">
          <div className="img-container">
            <img src="img/turn-on (1).png" alt="service" />
          </div>
          <h3>Switch</h3>
          <p>

          We offer smart switch solutions that enable seamless control of your lights, fans, and other appliances with just a touch or through your smartphone. These switches bring convenience and energy efficiency to your home or business by allowing you to turn devices on or off remotely, set schedules, or create automation routines. Effortlessly integrate them into your existing setup for enhanced control and comfort.
          </p>
        </div>
        <div className="card">
          <div className="img-container">
            <img src="img/shutter (1).png" alt="shutter" />
          </div>
          <h3>Shutter</h3>
          <p>
          We offer automated shutter solutions that provide effortless control over your window shutters. Whether for privacy, light management, or security, our smart shutters can be easily operated remotely or scheduled to open and close automatically. With seamless integration into your home automation system, you can enhance comfort and energy efficiency while adding a layer of convenience to your daily routine.
          </p>
        </div>
        <div className="card">
          <div className="img-container">
            <img src="img/home-theater (1).png" alt="home-cinema" />
          </div>
          <h3>Home Cinemas</h3>
          <p>
          We offer customized home cinema solutions that bring the ultimate entertainment experience right to your living room. With state-of-the-art audio, video, and smart control systems, you can enjoy immersive movie nights, sports events, or gaming in the comfort of your home. Our tailored setups ensure high-quality visuals and sound, creating a theater-like atmosphere for your family and guests.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ServiceList;
