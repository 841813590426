import React from 'react';
import '../../../styles/HeroSection.css';
import { Link } from 'react-router-dom';

function HeroSection() {
    return (
        <div className='HeroSection'>
            <div className="container">
                <div className="top-content"data-aos="fade"data-aos-duration="1000">
                    <h2>Smarter Living Starts Here</h2>
                    <p>Smart automation enhances convenience, boosts energy efficiency, and ensures security, offering seamless control over your environment for a more comfortable and safe living experience.</p>
                </div>
                {/* <div className="floating-icons-container">
                    <div class="floating-icon">
                        <div class="2"><i class="fa-solid fa-leaf"></i></div>
                        <div class="1"><i class="fa-solid fa-seedling"></i></div>
                        <div class="3"><i class="fa-solid fa-camera-retro"></i></div>
                        <div class="4"><i class="fa-brands fa-envira"></i></div>
                        <div class="6"><i class="fa-solid fa-carrot"></i></div>
                        <div class="7"><i class="fa-solid fa-bag-shopping"></i></div>
                        <div class="8"><i class="fa-solid fa-seedling"></i></div>
                        <div class="5"><i class="fa-solid fa-plane"></i></div>
                        <div class="9"><i class="fa-solid fa-location-dot"></i></div>
                        <div class="10"><i class="fa-solid fa-stopwatch"></i></div>
                    </div>
                </div> */}
                <div className="img-container"data-aos="zoom-out"data-aos-duration="1000">
                    <img src="../img/herosection.png" alt="herosection" />
                </div>
                
                <div className="brief-about"data-aos="fade"data-aos-duration="1000">
                    <h2>Welcome To Casero technologies</h2>
                    <p>Casero technologies, a leading provider of personalized automation and control solutions, allows you to control virtually any device in a home or business, automatically.The company mission is to deliver an elegant and more affordable way to control.</p>
                    <Link to={'about'}>About Us</Link>
                </div>



            </div>

        </div>
    )
}

export default HeroSection