import React from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import BreadCrumb from '../Components/Common/BreadCrumb/BreadCrumb'
import TestimonialList from '../Components/TestimonialsPage/TestimonialList/TestimonialList'
import Footer from '../Components/Common/Footer/Footer'

function Testimonial() {
  return (
    <div className='Testimonial'>
      <Navbar />
      <BreadCrumb nav={'Testimonial'} />
      <TestimonialList />
      <Footer />

    </div>
  )
}

export default Testimonial