import React from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import AboutUs from '../Components/AboutPage/AboutUs/AboutUs'
import BreadCrumb from '../Components/Common/BreadCrumb/BreadCrumb'
import CounterSection from '../Components/AboutPage/CounterSection/CounterSection'
import ContactForm from '../Components/Common/ContactForm/ContactForm'
import Footer from '../Components/Common/Footer/Footer'

function About() {
  return (
    <div className='About'>
      <Navbar />
      <AboutUs />
      <CounterSection />
      <ContactForm/>
      <Footer/>
      
    


    </div>
  )
}

export default About