import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "../../../styles/Testimonials.css";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";

function Testimonials() {
  return (
    <div className="Testimonials">
      <div className="container">
        <div className="top-content" data-aos="fade" data-aos-duration="1000">
          <h2>WE HEAR FROM OUR CUSTOMERS</h2>
          <p>
            We prioritize customer feedback to refine and improve our products
            and services, ensuring we meet the evolving needs of our community
            with excellence and dedication.
          </p>
          <Link to={"/testimonial"}>Explore more</Link>
        </div>
        <div
          className="card-container"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <Swiper
            slidesPerView={1}
            spaceBetween={20}
            pagination={{
              type: "fraction",
            }}
            navigation={false}
            speed={1000}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, EffectFade, Navigation, Pagination]}
            className="mySwiper"
            breakpoints={{
              768: {
                slidesPerView: 2,
                spaceBetween: 10,
              },
              992: {
                slidesPerView: 3,
                spaceBetween: 10,
              },
            }}
          >
            <SwiperSlide>
              <div className="card">
                <p>
                  The team of Casero Technologies is very supportive and
                  professionals. I have very good experience with this team.
                  Brilliant solution makers and supplies premium quality
                  products. Service's are also on time. So we don't have to
                  worry about any other things.
                </p>
                <div className="profile">
                  <img src="img/testimonials/BEATDROPPER.png" alt="profile" />
                  <h3>BEATDROPPER</h3>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <p>
                  Casero did an amazing job in our sites . We strongly recommend
                  Casero service given to us . Casero is the best shutter
                  automation in Kerala
                </p>
                <div className="profile">
                  <img src="img/avatar.webp" alt="profile" />
                  <h3>Subramanyan V.A</h3>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <p>
                  I found them to be very professional and they knew what they
                  were doing. When I asked for changes they did it without any
                  fuss. Thank you all for an excellent job done!
                </p>
                <div className="profile">
                  <img src="img/avatar.webp" alt="profile" />
                  <h3>M PC</h3>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <p>
                  I have loved the fact that Casero Technologies is very
                  professional in their approach and they gave me that dream
                  automated home that I always wanted . I would recommend them
                  to everyone who is looking for home automation services.
                </p>
                <div className="profile">
                  <img src="img/testimonials/Aswathi.png" alt="profile" />
                  <h3>Aswathi Dinil</h3>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="card">
                <p>
                  Very good behaviour The quality of the work is superb really
                  professional, the work was completed on date I am very happy
                  with the work thank you casero technologies.
                </p>
                <div className="profile">
                  <img src="img/testimonials/Ashwin.png" alt="profile" />
                  <h3>Ashwin pukaat</h3>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
            <div className="card">
            <p>
            Excellent automation services. Cctv automate doors gate ac etc, whatever our needs casero will make our life much easier and save lot of time. Keep going guys.
            </p>
            <div className="profile">
            <img src="img/avatar.webp" alt="profile" />
              <h3>Faheem V Sulaiman</h3>
            </div>
          </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
