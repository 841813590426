import React, { useContext, useEffect, useState } from 'react';
import '../../../styles/Navbar.css';
import { Link, useLocation } from 'react-router-dom';
import MenuContext, { MenuProvider } from '../../../Context/menuProvider';

function Navbar() {
  const { menuOpen, handleToggleMenu, navbar, handleNavbar, setImage, overlay, image, setOverlay } = useContext(MenuContext);
  const location = useLocation(); // Get the current location

  const handleMenuItemClick = () => {
    if (window.innerWidth <= 768) {
      handleToggleMenu();
    }
  };

  const shouldReload = (path) => {
    // Check if the clicked link matches the current location
    return location.pathname === path;
  };

  return (
    <div>
      <div className='Navbar'>
        <header className={navbar ? 'header active' : 'header'}>
          <div className="logo">
            <Link to="/"><img src={image} alt="Sahya logo" /></Link>
          </div>
          <nav className='nav-menu'>
            <ul className={menuOpen ? 'nav-links active' : 'nav-links'} >
              <li>
                <Link onClick={() => { handleMenuItemClick(); shouldReload('/') && window.location.reload(); }} to="/">Home</Link>
              </li>
              <li>
                <Link onClick={() => { handleMenuItemClick(); shouldReload('/about') && window.location.reload(); }} to="/about">About Us</Link>
              </li>


              <li>
                <Link onClick={() => { handleMenuItemClick(); shouldReload('/services') && window.location.reload(); }} to="/services">Services</Link>
              </li>
              <li>
                <Link onClick={() => { handleMenuItemClick(); shouldReload('/testimonial') && window.location.reload(); }} to="/testimonial">Testimonial</Link>
              </li>
              

              <li>
                <Link onClick={() => { handleMenuItemClick(); shouldReload('/contact') && window.location.reload(); }} to="/contact">Contact</Link>
              </li>

            </ul>
            <div className="menu-button">
              <label htmlFor="check">
                <input type="checkbox" id="check" onClick={handleMenuItemClick} />
                <span></span>
                <span></span>
                <span></span>
              </label>
            </div>
            <div className={overlay ? 'overlay active' : 'overlay'}></div>
          </nav>
          <div className="get-in-touch">
            <Link to="/contact/#get-in-touch"><span>Get In Touch <span><img src="../img/support.png" alt="get in touch" /></span></span></Link>
          </div>
          
        </header>
      </div>
    </div>
  );
}

export default Navbar;
