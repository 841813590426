import React from 'react'
import { Link } from 'react-router-dom';
import '../../../styles/Footer.css'


function Footer() {
  return (
    <div className='Footer'>
        <div className="container">
            <div className="row"data-aos="fade"data-aos-duration="1000"data-aos-offset="0">
                <div className="logo">
                    <img src="../img/casero-black-logo.png" alt="logo" style={{width:'66px'}} />
                </div>
                <ul>
                    <li><Link to={'/'}>Home</Link></li>
                    <li><Link to={'/'}>About Us</Link></li>
                    <li><Link to={'/'}>Contact Us</Link></li>
                </ul>
                <div className="social-media">
                    <ul>
                        <li><a href="#"><span><i class="fa-brands fa-facebook-f"></i></span></a></li>
                        <li><a href="#"><span><i class="fa-brands fa-twitter"></i></span></a></li>
                        <li><a href="#"><span><i class="fa-brands fa-instagram"></i></span></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer