import React from 'react'
import Navbar from '../Components/Common/Navbar/Navbar'
import BreadCrumb from '../Components/Common/BreadCrumb/BreadCrumb'
import ServiceIntro from '../Components/ServicePage/ServiceIntro/ServiceIntro'
import ServiceList from '../Components/ServicePage/ServiceList/ServiceList'
import ContactForm from '../Components/Common/ContactForm/ContactForm'
import Footer from '../Components/Common/Footer/Footer'

function Services() {
  return (
    <div className='Services'>
      <Navbar />
      <BreadCrumb nav={'Services'} />
      <ServiceIntro />
      <ServiceList/>
      <ContactForm/>
      <Footer/>

    </div>
  )
}

export default Services