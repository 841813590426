import React from "react";
import "../../../styles/ContactDetails.css";

function ContactDetails() {
  return (
    <div className="ContactDetails">
      <div className="container">
        <div className="top-content">
          <h2>CONTACT US</h2>
          <p>Feel free to contact us with your queries.</p>
        </div>
        <div className="details">
          <p data-aos="fade" data-aos-duration="1000" data-aos-delay="0">
            <span>
              <img src="img/contact-details/location.png" alt="location" />
            </span>
            Casero Technologies First Floor, 25/1960D, 25/1906E, Panath Complex,
            Panathuthazham Kottooli (po) Kozhikode-673016
          </p>
          <p data-aos="fade" data-aos-duration="1000" data-aos-delay="0">
            <span>
              <img src="img/contact-details/mail.png" alt="mail" />
            </span>
            <a href="caserotechnologies@gmail.com">
              caserotechnologies@gmail.com
            </a>
          </p>
          <p data-aos="fade" data-aos-duration="1000" data-aos-delay="0">
            <span>
              <img src="img/contact-details/phone.png" alt="phone" />
            </span>
            <a href="tel:+91 99527 23392">+91 99527 23392</a>
          </p>
          <p data-aos="fade" data-aos-duration="1000" data-aos-delay="0">
            <span>
              <img src="img/contact-details/phone.png" alt="phone" />
            </span>
            <a href="tel:+91 8089233013">+91 8089233013</a>

          </p>
        </div>
      </div>
    </div>
  );
}

export default ContactDetails;
