import React from 'react';
import '../../../styles/AboutUs.css'
import BreadCrumb from '../../Common/BreadCrumb/BreadCrumb';

function AboutUs() {
    return (
        <div className='AboutUs'>
            <BreadCrumb nav={'About'} />
            <div className="container"data-aos="fade"data-aos-duration="1000">
                <h2>
                    HEY THERE ? CASERO HERE , HELLO!</h2>
                <p>Casero technologies, a leading provider of personalized automation and control solutions, allows you to control virtually any device in a home or business, automatically.The company mission is to deliver an elegant and more affordable way to control and automate lighting, gate,music, video, security and energy in a single room or throughout the entire home.

                    <br></br>
                    <br></br>
                    Today, Casero technologies certified custom integrators work with home and business owners to deliver the transformative benefits of the smart and connected home: convenience, peace of mind and an efficient environment that’s comfortable and safe. Casero technologies leads the industry in interoperability, ensuring an ever-expanding eco-system of devices will work together in the home or business.

                    </p>
            </div>
        </div>
    )
}

export default AboutUs