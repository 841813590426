import './App.css';
import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import About from './Pages/About';
import Services from './Pages/Services';
import Testimonial from './Pages/Testimonial';
import Contact from './Pages/Contact';
import ScrollToTop from './Components/Common/ScrollToTop/ScrollToTop';
import CustomCursor from './Components/Common/CustomCursor/CustomCursor';
import Whatsapp from './Components/Common/Whatsapp_btn/Whatsapp';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <CustomCursor/>
        <Whatsapp/>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/services' element={<Services />} />
          <Route path='/testimonial' element={<Testimonial />} />
          <Route path='/contact' element={<Contact />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
