import React from 'react';
import '../../../styles/BriefServices.css'

function BriefServices() {
    return (
        <div className='BriefServices'>
            <div className="container">
                <div className="row">
                    <div className="left-col"data-aos="fade"data-aos-duration="1000">
                        <h2>We Can Help You With</h2>
                        <p>We provide solutions for seamless control of lighting, security, entertainment, and energy, transforming your space into a smart, efficient, and comfortable environment.</p>
                    </div>
                    <div className="right-col"data-aos="fade-up"data-aos-duration="1000">
                        <div className="card">
                            <img src="img/cctv (1).png" alt="cctv" />
                            <h3>Camera</h3>
                            <p>Casero Technologies offers CCTV camera solutions for secure, real-time monitoring with remote access, tailored to fit your home or business needs.</p>
                        </div>
                        <div className="card even">
                            <img src="img/gate (4).png" alt="gate " />
                            <h3>Gate</h3>
                            <p>Casero Technologies provides automated gate solutions for convenient and secure access control, allowing you to open and close gates remotely or automatically.</p>
                        </div>
                        <div className="card">
                            <img src="img/smart-light (1).png" alt="light automation" />
                            <h3>Light automation</h3>
                            <p>
                            We offer advanced light automation, giving you complete control over your lighting system. You can easily schedule, adjust brightness, and manage lighting for individual rooms or entire spaces, enhancing convenience, ambiance, and energy efficiency.</p>
                        </div>
                        <div className="card even">
                            <img src="img/turn-on (1).png" alt="switch" />
                            <h3>Switch </h3>
                            <p>We offer smart switch solutions, allowing you to control lights, fans, and other appliances effortlessly from anywhere, adding convenience and energy savings to your home or business.</p>
                        </div>
                        <div className="card">
                            <img src="img/shutter (1).png" alt="shutter" />
                            <h3>Shutter</h3>
                            <p>We offer automated shutter solutions, enabling you to control and schedule window shutters for enhanced privacy, security, and light control with ease.</p>
                        </div>
                        <div className="card even">
                            <img src="img/home-theater (1).png" alt="home-cinema" />
                            <h3>Home cinemas</h3>
                            <p>We offer home cinema solutions, delivering a high-quality audio and visual experience with customizable setups for immersive movie nights and entertainment.</p>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BriefServices